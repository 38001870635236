<template>
  <div
    class="block mt-6 mb-6 md:mb-10 flex flex-col md:flex-row flex-wrap justify-center"
  >
  <Project
   v-for="(item, index) in projects"
   :name="item.name"
   :type="item.type"
   :description="item.description"
   :logo="'img/projects/'+item.logo"
   :image="'img/projects/'+item.mainImage"
   :color="item.color"
   :key="index"
 ></Project>
  </div>
</template>

<script>
import { Project } from "@/components";
import projects from "@/projects.json"

export default {
  components: {
    Project
  },
  data() {
    return {
      projects: projects
    };
  }
};
</script>

<style lang="css" scoped></style>
