<template>
  <section
    class="min-h-60 flex flex-col xl:flex-row justify-around items-center bg-grey-lighter section flex-wrap"
  >
    <div class="flex flex-col items-center flex-1">
      <div>
        <h2 class="text-left mb-6 h1">
          Partners &amp;
          <span class="stroke-text h1">Clients</span>
        </h2>
        <div class="partners-section-text">
          CodeLane Solutions worked with over 10 different clients ranging
          over individuals to small startups to companies.
          <br />
          <br />
          We also managed to build prolonged-term, trustful connections
          with our clients and succeed in achieving great results
          that made everyone satisfied.
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center xl:items-start flex-1">
      <div class="flex flex-col sm:flex-row xl:ml-32">
        <div class="img-bg sm:mr-20">
          <a
            href="https://www.spiralup.co/"
            target="_blank"
            rel="noopener"
            ><img
              src="img/home/partners/spiral.png"
              alt="Exenzo"
              class="image-position lazyload"
          /></a>
        </div>
        <div class="img-bg">
          <a
            href="https://www.allstacks.com/"
            target="_blank"
            rel="noopener"
            ><img
              src="img/home/partners/allstacks.png"
              alt="Voicenter"
              class="image-position lazyload"
          /></a>
        </div>
      </div>
      <div class="flex flex-col sm:flex-row xl:-mt-6 xl:-mb-6">
        <div class="img-bg sm:mr-24">
          <a
            href="https://tucr.io/"
            target="_blank"
            rel="noopener"
            ><img
              src="img/home/partners/Tucr.png"
              alt="Markerfleet"
              class="image-position lazyload"
          /></a>
        </div>
        <div class="img-bg">
          <a
            href="https://hourstack.com/"
            target="_blank"
            rel="noopener"
            ><img
              src="img/home/partners/hourstack.png"
              alt="Tagup"
              class="image-position lazyload"
          /></a>
        </div>
      </div>
      <div class="flex flex-col sm:flex-row xl:ml-32">
        <div class="img-bg sm:mr-20">
          <a
            href="https://www.billflow.io/"
            target="_blank"
            rel="noopener"
            ><img
              src="img/home/partners/billflow.png"
              alt="Creative Tim"
              class="image-position lazyload creative-tim-logo"
          /></a>
        </div>
        <div class="img-bg">
          <a
            href="https://formkeep.com/"
            target="_blank"
            rel="noopener"
            ><img
              src="img/home/partners/formkeep.png"
              alt="Mobley"
              class="image-position lazyload"
          /></a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
