<template>
  <section
    class="min-h-70 flex flex-col justify-center items-center bg-grey-lighter pt-24 px-8 md:px-0 lg:px-20 relative"
  >
    <h2 class="h1">
      Our <span class="stroke-text h1">Recent</span> Projects
    </h2>
    <div class="sm:mt-10 xl:mx-24 lg:mx-16 md:mx-0">
      <Products></Products>
    </div>
  </section>
</template>

<script>
import Products from "@/views/subComponents/Products.vue";

export default {
  components: {
    Products
  }
};
</script>
