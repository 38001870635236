<template>
  <section
    class="min-h-70 flex flex-col lg:flex-row items-center lg:items-start justify-around bg-white section"
  >
    <div class="flex flex-col flex-1 items-center">
      <div>
        <h2 class="text-left mb-6 h1">Technologies</h2>
        <div class="partners-section-text">
          CodeLane Solutions use varieties of well established and proven technologies,
          and also the ones that help you scale and improve your efficiency
          <br />
          <br />
          <a
            href="https://vuejs.org"
            target="_blank"
            rel="noopener"
            >Vue.js</a
          >,
          <a
            href="https://www.python.org/"
            rel="noopener"
            target="_blank"
            >Python</a
          >
          and
          <a
            href="https://nodejs.org"
            target="_blank"
            rel="noopener"
            >Node.js</a
          >
          are some of some the examples
          of technologies which we use a daily basis
          for building amazing Applications for our clients
        </div>
      </div>
    </div>
    <div class="flex flex-col flex-1 w-64">
      <div class="flex flex-row justify-center m-4">
        <a
          href="https://nodejs.org"
          target="_blank"
          rel="noopener"
          class="cursor-pointer mr-20"
          ><img
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/nodejs/nodejs.png"
            alt="Nodejs"
            height="49"
            width="182"
            class="lazyload"
          />
        </a>
        <a
          href="https://reactjs.org"
          target="_blank"
          rel="noopener"
          class="cursor-pointer"
        >
          <img
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/react/react.png"
            alt="React"
            height="74"
            width="83"
            class="lazyload"
        /></a>
      </div>
      <div class="flex flex-row justify-center m-4">
        <a
          href="https://www.python.org/"
          target="_blank"
          rel="noopener"
          class="mr-20 cursor-pointer"
        >
          <img
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/python/python.png"
            alt="Laravel"
            height="90"
            width="168"
            class="lazyload"
        /></a>

        <a
          href="https://www.postgresql.org/"
          target="_blank"
          rel="noopener"
          class="cursor-pointer"
        >
          <img
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/postgresql/postgresql.png"
            alt="Mysql"
            height="86"
            width="128"
            class="lazyload"
        /></a>
      </div>
      <div class="flex flex-row justify-center m-4">
        <a
          href="https://www.mongodb.com"
          target="_blank"
          rel="noopener"
          class="mr-20 cursor-pointer"
        >
          <img
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/mongodb/mongodb.png"
            alt="Mongodb"
            height="80"
            width="296"
            class="lazyload"
        /></a>
        <a
          href="https://vuejs.org"
          target="_blank"
          rel="noopener"
          class="cursor-pointer"
          ><img
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/vue/vue.png"
            alt="Vue"
            height="46"
            width="122"
            class="lazyload"
        /></a>
      </div>
      <div class="flex flex-row justify-center m-4">
        <a
          href="https://developers.google.com/web/progressive-web-apps"
          target="_blank"
          rel="noopener"
          class="mr-20 cursor-pointer"
          ><img
            src="https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/pwa/pwa.png"
            alt="Pwa"
            height="32"
            width="128"
            class="lazyload"
        /></a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
