<template>
  <div class="about">
    <main>
      <div>
        <section
          class="min-h-screen flex flex-col justify-center items-center bg-secondary-dark"
        >
          <div>
            <div class="swiper-wrapper">
              <div class="swiper-slide flex justify-center">
                <div class="max-w-lg flex flex-col items-center">
                  <h1 class="text-white text-center">
                    We Provide Solutions to Your Coding Needs.
                  </h1>
                  <p class="header-secondary my-8">
                    Got an idea ? Let's make it a reality.
                  </p>
                  <button @click="$router.push('/estimate-project')" class="btn primary btn-default">
                    Estimate Project
                  </button>
                </div>
              </div>
            </div>
          </div>
          <img
            src="https://cdn.pixabay.com/photo/2017/05/19/06/22/desk-2325627_960_720.jpg"
            alt="Hero image"
            class="bg-image opacity-30"
          />
        </section>
        <CaseStudies></CaseStudies>
        <Partners></Partners>
        <Success></Success>
        <Technologies></Technologies>
        <ContactSection></ContactSection>
      </div>
    </main>
  </div>
</template>

<script>
import ContactSection from "./components/ContactSection.vue";
import CaseStudies from "./components/CaseStudies.vue";
import Partners from './components/Partners.vue'
import Success from './components/Success.vue'
import Technologies from './components/Technologies.vue'


export default {
  components: {
    ContactSection,
    CaseStudies,
    Partners,
    Success,
    Technologies
  }
};
</script>
