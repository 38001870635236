<template lang="html">
  <section
    id="contact-section"
    class="flex flex-col justify-center items-center bg-primary section"
    :class="{'min-h-80':!succeed,'min-h-60':succeed}"
  >
    <img
      src="img/contact/contact.svg"
      alt="Heading icon"
      class="top-heading__icon"
    />
    <h2 class="text-white h1">Contact Us</h2>
    <h1 v-if="succeed" class="text-white">Thank you for contacting us. We will respond to your message shortly.</h1>
    <div v-if="!succeed" class="w-full max-w-sm">
      <ContactInput
        v-model="form.name"
        :error="field.name"
        placeholder="Name"
      ></ContactInput>
      <ContactInput
        v-model="form.email"
        :error="field.email"
        placeholder="Email Address"
      ></ContactInput>
      <ContactInput
        v-model="form.phone"
        :error="field.phone"
        placeholder="Your Phone (optional)"
      ></ContactInput>
      <div class="mt-3 mb-1">
        <div
          id="message"
          placeholder="How can we help ?"
          aria-label="Full name"
          class="text-area"
        >
          <label for="textarea" class="sr-only">
            Message
          </label>
          <div class="el-textarea">
            <textarea
              autocomplete="off"
              id="textarea"
              placeholder="How can we help ?"
              rules="required"
              name="message"
              class="el-textarea__inner"
              style="min-height: 122px; height: 122px;"
              v-model="form.message"
            ></textarea>
            <span
              v-if="field.message"
              class="error self-start text-primary text-xs pt-1 text-white"
              >{{ field.message }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="!succeed" class="flex justify-center mt-16">
      <n-button @click="onSubmit()" class="submit-button white"
        ><span class="pl-3 pr-2 text-lg">Send</span>
        <div class="icon__content">
          <i class="fas fa-arrow-right send-arrow"></i></div
      ></n-button>
    </div>
  </section>
</template>

<script>
import { Button, ContactInput } from "@/components";
import axios from "axios";
import qs from "querystring";

export default {
  data() {
    return {
      config: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      },
      succeed:false,
      error: "",
      field: {
        name: "",
        email: "",
        phone: "",
        message: ""
      },
      form: {
        id: "contact",
        name: "",
        email: "",
        phone: "",
        message: ""
      }
    };
  },
  components: {
    [Button.name]: Button,
    ContactInput
  },
  methods: {
    onSubmit() {
      if (this.validateEmail(this.form.email)) {
        this.field.email = ""
        if (this.form.name) {
          this.field.name = ""
          if (this.form.message) {
            this.field.message = ""
            axios
              .post(
                this.$company.formLink,
                qs.stringify(this.form),
                this.config
              )
              .then(() => {
                this.field.name = "";
                this.field.email = "";
                this.field.message = "";
                this.field.phone = "";
                this.form.name = "";
                this.form.email = "";
                this.form.message = "";
                this.form.phone = "";
                this.succeed = true;
              })
              .catch(err => {
                this.field.name = "";
                this.field.email = "";
                this.field.message = "";
                this.field.phone = "";
                this.error = err.message;
              });
          } else {
            this.field.message = "This field is required";
          }
        } else {
          this.field.name = "The name field is required";
        }
      } else {
        if (this.field.email === "") {
          this.field.email = "The email field is required";
        } else {
          this.field.email = "The email field must be a valid email";
        }
      }
    },
    validateEmail(email) {
      /* eslint-disable */
      var re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      return re.test(String(email).toLowerCase());
    }
  }
};
</script>

<style lang="css" scoped></style>
